import React from 'react';
import Logo from './logo';
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { GatsbyImage } from "gatsby-plugin-image";
import Sidebar from "../components/sidebar";
import Copyright from './copyright';

const MobilePage = ({background}) => {
  return (
  <>
    <main>
      <div className="background-image">
        <GatsbyImage
        image={background}
        alt="landing background"/>
      </div>
    <Sidebar/>
    <div className="landing-copyright"><Copyright/></div>
    </main>
  </> );
}


 
export default MobilePage;