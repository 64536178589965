// extracted by mini-css-extract-plugin
export var desktopBumper = "_desktop-module--desktop-bumper--3AGcL";
export var blockColor = "_desktop-module--block-color--3HJRO";
export var gradientColor = "_desktop-module--gradient-color--1AF1f";
export var bumperContent = "_desktop-module--bumper-content--1bITS";
export var contentWrapper = "_desktop-module--content-wrapper--BN-YA";
export var logoWrapper = "_desktop-module--logo-wrapper--1FjSj";
export var titleWrapper = "_desktop-module--title-wrapper--2FXNG";
export var titleDescription = "_desktop-module--title-description--1uOpU";
export var buttonWrapper = "_desktop-module--button-wrapper--Iq6H-";
export var callToAction = "_desktop-module--call-to-action--3vrom";
export var bgImage = "_desktop-module--bg-image--1-dQs";