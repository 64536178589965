import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import "../style/main.scss";
import { isBrowser } from "react-device-detect";
import DesktopPage from "../components/desktopPage";
import MobilePage from "../components/mobilePage";
import {getImage} from "gatsby-plugin-image";

const IndexPage = ({ data }) => {
  const imageArray = data.allFile.nodes;
  const randomNumber = Math.floor(Math.random() * imageArray.length);
  const background = getImage(
    imageArray[randomNumber].childImageSharp.gatsbyImageData
  );

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Teko Merah | We bring delight to your room</title>
      <meta name="author" content="readmerisen"/>
      <meta name="description" content="Teko Merah is a growing interior design studio.
      Our scope of work includes design, fabrications, and constructions. We are ready to bring delightful
      design to your room."/>
      <meta name="twitter:title" content="Teko Merah Design Studio"></meta>
    </Helmet>

    {isBrowser ? <DesktopPage/> : <MobilePage background={background} />}</>
  );
};

export const query = graphql`
query{
  allFile(filter: {sourceInstanceName: {eq: "bumpers"}}) {
    nodes {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
}`

export default IndexPage;
