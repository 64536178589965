import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import DesktopLayout from "./desktop/desktopLayout";
import { desktopBumper, bumperContent, bgImage } from '../style/layout/_desktop.module.scss';
import { useEffect } from "react";
import { useState } from "react";
import Copyright from "./copyright";

const BackgroundOne = () => {
  return (
  <StaticImage
    src="../images/projects/tmbdmall.jpg"
    alt="hero image"/>
    );
}

const BackgroundTwo = () => {
  return (
  <StaticImage
    src="../images/projects/btvg.jpg"
    alt="hero image"/>
    );
}

const BackgroundThree = () => {
  return (
  <StaticImage
    src="../images/projects/idof.jpg"
    alt="hero image"/>
    );
}


const DesktopPage = () => {
  const [background, setBackground] = useState();

  useEffect(() => {
    const random = Math.floor(Math.random()*3);
    console.log(random);
    switch(random) {
      case 0: 
        setBackground(<BackgroundOne/>);
        break;
      case 1:
        setBackground(<BackgroundTwo/>);
        break;
      case 2:
        setBackground(<BackgroundThree/>);
        break;
    }
  }, []);

  const year = new Date().getFullYear();
  
  return (
    <>
      <DesktopLayout>
        <div className={desktopBumper}>
          <div className={bumperContent}>
            <div className={bgImage}>
              {background}
            </div>
          </div>
        </div>
        <div className="copyright dsk-landing-copyright">
          TEKOMERAH &copy; {year}
        </div>
      </DesktopLayout>
    </>
  );
};

export default DesktopPage;
